<template>
    <div class=" d-flex ">
        <div>
            <OActionSave class="btn btn-sm btn-primary" :dataObject="props.dataObject">{{$t('Save')}}</OActionSave>
            <OActionCancel class="btn btn-sm btn-secondary ms-1" :dataObject="props.dataObject">{{$t('Cancel')}}</OActionCancel>
        </div>
        <slot></slot>
    </div>
</template>


<script setup> 
import {OActionSave, OActionCancel}  from "o365-data-components";
import { ref, onUpdated, onBeforeUpdate, onMounted, useSlots } from 'vue';

const props = defineProps(
    {
        dataObject: {
            type: Object,
            default: undefined
        },
        row: {
            type: Object,
            default: undefined
        }
    }
);
</script>   

